a {
	color: $color__link;
	text-decoration: none;

	&:visited {
		color: $color__link-visited;
	}
	&:hover,
	&:focus,
	&:active {
		color: $color__link-hover;
	}
	&:focus {
		outline: thin dotted;
	}
	&:hover,
	&:active {
		outline: 0;
	}
}