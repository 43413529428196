.wp-caption {
	margin-bottom: 1.5em;
	max-width: 100%;

	img[class*="wp-image-"] {
		@include center-block;
	}

	.wp-caption-text {
		margin: 0.5em 0;
		font-style: italic;
		text-align: left;
	}
}

.wp-caption-text {
	text-align: center;
}
