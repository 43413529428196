body,
button,
input,
select,
textarea {
	color: $color__text-main;
	font-family: $font__main;
	@include font-size(1);
	line-height: $font__line-height-body;
}

@import "headings";

@import "copy";