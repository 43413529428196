/*!
Theme Name: fccom
Theme URI: http://underscores.me/
Author: Francis Cortez
Author URI: http://franciscortez.com
Description: Description
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: fccom
Tags:

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned with others.

fccom is based on Underscores http://underscores.me/, (C) 2012-2016 Automattic, Inc.
Underscores is distributed under the terms of the GNU GPL v2 or later.

Normalizing styles have been helped along thanks to the fine work of
Nicolas Gallagher and Jonathan Neal http://necolas.github.com/normalize.css/
*/

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Normalize
# Typography
# Elements
# Forms
# Navigation
	## Links
	## Menus
# Accessibility
# Alignments
# Clearings
# Widgets
# Content
	## Posts and pages
	## Comments
# Infinite scroll
# Media
	## Captions
	## Galleries
--------------------------------------------------------------*/
@import "variables-site/variables-site";
@import "mixins/mixins-master";

/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
@import "normalize";

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
@import "typography/typography";

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
@import "elements/elements";

/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
@import "forms/forms";

/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/
@import "navigation/navigation";

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
@import "modules/accessibility";

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
@import "modules/alignments";

/*--------------------------------------------------------------
# Clearings
--------------------------------------------------------------*/
@import "modules/clearings";

/*--------------------------------------------------------------
# Widgets
--------------------------------------------------------------*/
@import "site/secondary/widgets";

/*--------------------------------------------------------------
# Content
--------------------------------------------------------------*/
@import "site/site";

/*--------------------------------------------------------------
# Infinite scroll
--------------------------------------------------------------*/
@import "modules/infinite-scroll";

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/
@import "media/media";

@import "bourbon/bourbon";
@import "neat/neat";
//@import "custom";

//@import "uxbooks";

@import "media/media";
/* trying to make something simpler — flex layout and stuff like that */

@import url('https://fonts.googleapis.com/css?family=Playfair+Display:900|Lato:400,400i,900');



//$sevenplus: new-breakpoint(min-width 414px 12);
$desktop: new-breakpoint(min-width 768px 12);
$bigdesktop: new-breakpoint(min-width 926px 12);

.allposts {
  //background: orange;
  padding-top: 24px;
  @include media($desktop) {
    padding-top: 48px;
  }
}

.allposts__post {
  @include outer-container;
  padding-bottom: 24px;

  &:first-child { padding-bottom: 0; }
  @include media($desktop) {
    &:first-child { padding-top: 0; }
    padding-top: 24px;
    border-bottom: 1px solid #fafafa;
    //padding-left: 128px;
    //padding-right: 128px;
  }
}

//.allposts__post.category-podcast .post-categories {
//  li a {
//    background-color: #50E3C2;
//    color: #000;
//    font-weight: bold;
//  }
//  .fadeout {
//    display: none;
//  }
//}

/* Set the grid up here */
.allposts__meta {
  @include span-columns(12);
  padding: 0 0 0 12px;
  @include span-columns(4);
  margin-right: 0;
  @include media($desktop) {
    @include span-columns(3);
    @include shift(1);
    padding: 0;
    margin-right: 0;
  }
}
.allposts__title-excerpt {
  @include span-columns(12);
  padding: 0 12px 0 0;
  //padding: 12px;
  //max-height: 8em;


  @include span-columns(8);
  @include media($desktop) {
    @include span-columns(7);
    padding: 0;
  }
}
/* End grid */

.allposts__date {
  clear: right;
  text-align: right;
  margin-bottom: 4px;
}

.allposts__date, .allposts__categories {
  text-transform: uppercase;
  color: #777;
  //text-align: center;
  font-size: .7rem;
  letter-spacing: .5px;
  //text-align: right;
  margin-top: 0;
  @include media($desktop) {
    font-size: .8rem;
    letter-spacing: 1px;
    width: 100%;
  }
}

.allposts__thumbnail {
  //max-width: 50%;
  //margin-top: 8px;
  margin-bottom: 8px;
  clear: right;
  img {
    display: block;
    margin: 0 auto;
    max-height: 120px;
    width: auto;
    max-width:100%;
  }
  float: right;
  @include media($desktop) {
    max-width: 80%;
  }
}

.allposts__categories {
  //display: none;
  @include media($desktop) {
    display: block;
    clear: right;
  }
}
.allposts__categories ul {
  list-style-type: none;
  display: inline;
  li {
    display: inline;
    float: right;
    //margin-right: 8px;
  }
  margin-right: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.allposts__categories a {
    display: inline-block;
    float: right;
    margin-left: 8px;
  text-align: right;
    //clear: right;
    margin-bottom: 8px;
    color: #ccc;
  //float: ;
  //margin: 0 auto;

  @include media($desktop) {
    display: block;
    //float: right;
    //margin-left: 8px;
  }
}

.allposts__categories a {
    //padding: 2px 8px;
    border-radius: 4px;
  font-style: italic;
  //background-color: #fafafa;
}

.allposts__posts {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

.allposts__title-excerpt {
  padding-left: 16px;
  //text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;

  position: relative;
  @include media($desktop) {
    text-align: left;
  }
}

.allposts__title {
  font-size: .9rem;
  margin-bottom: 0;
  margin-top: 0;
  @include media($desktop) {
    font-size: 1rem;
    line-height: 1rem;
    padding: 0;
    margin: 0;
    max-width: 380px;
  }
}

.allposts__excerpt p {
  margin-top: 8px;
  color: #333;
  font-size: .8rem;
  //max-width: 95%;
  //margin: 0 auto;
  @include media($desktop) {
    font-size: .9rem;
    margin: 0;
    margin-top: 4px;
    max-width: 380px;
  }
  //line-height: normal;
}

.fadeout {
  pointer-events: none;
  position: absolute;
  bottom: 0em;
  width: calc(100% - 28px);
  height: 5em;
  background: -webkit-linear-gradient(
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
  );
  background-image: -moz-linear-gradient(
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
  );
  background-image: -o-linear-gradient(
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
  );
  background-image: linear-gradient(
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
  );
  background-image: -ms-linear-gradient(
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
  );
  opacity: 0.75;
}

//#content * { background-color: rgba(255,0,0,0.1) }

//.post {
//  overflow: auto;
//  padding-top: 2rem;
//  padding-bottom: 2rem;
//  border-bottom: 1px solid #000;
//  position: relative;
//}