button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	border: 1px solid;
	border-color: $color__border-button;
	border-radius: 3px;
	background: $color__background-button;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), inset 0 15px 17px rgba(255, 255, 255, 0.5), inset 0 -5px 12px rgba(0, 0, 0, 0.05);
	color: rgba(0, 0, 0, .8);
	@include font-size(0.75);
	line-height: 1;
	padding: .6em 1em .4em;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);

	&:hover {
		border-color: $color__border-button-hover;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.8), inset 0 15px 17px rgba(255, 255, 255, 0.8), inset 0 -5px 12px rgba(0, 0, 0, 0.02);
	}

	&:active,
	&:focus {
		border-color: $color__border-button-focus;
		box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.5), inset 0 2px 5px rgba(0, 0, 0, 0.15);
	}
}